body {
  background-color: cornflowerblue;
}

.container {
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;

}

header {
  text-align: center;
  border-bottom: 1px solid cornflowerblue;

}

.selected {
  font-weight: bold;
}

.list,
.race-list,
.class-list,
.monster-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1rem;
}

.item a,
.class-item,
.race-item,
.monster-item {
  color: #000;
  transition: color ease-in-out 200ms;
}

.item a:hover,
.class-item:hover,
.race-item:hover .monster-item:hover {
  cursor: pointer;
  color: cornflowerblue
}

.details,
.race-details,
.class-details,
.monster-details {
  margin: 1rem;
  padding: 1rem;
  border: 1px solid cornflowerblue;
  border-radius: 1rem;
}

h2 {
  margin: 1rem;
}

.spinner {
  display: grid;
  place-content: center;
}

.spinner svg {
  max-width: 300px;
  height: auto;
}

.monster-image img {
  width: 100%;
  height: auto;
  max-width: 100%;
}

ul.stats {
  margin: 0;
  padding: 0;
  list-style: none;
}